<template>
  <v-container fluid class="pa-0 test">
    <h1 class="text-center primary--text">CONTACTO</h1>
    <v-form ref="form" @submit.prevent="sendMail">
      <v-row class="ma-0 pa-0">
        <v-col cols="8" class="pt-0 pb-1">
          <v-text-field clearable class=" v-superdense-input" hide-details="auto" outlined v-model="nombre" label="Nombre"></v-text-field>
        </v-col>
        <v-col cols="4" class="pt-0 pb-1">
          <v-btn color="info" @click="nombre = $store.getters.getTokenExtraPayload.nombre_completo" style="width: 100%; height: 100%;"><v-icon size="50">mdi-pencil</v-icon></v-btn>
        </v-col>
        <v-col cols="12" class="pt-0 pb-1">
          <v-text-field clearable class="mb-1 v-superdense-input" hide-details="auto" outlined v-model="email" label="E-mail"></v-text-field>
        </v-col>
        <v-col cols="12" class="pt-0 pb-1">
          <v-textarea clearable :rules="[rules.req]" v-model="message" hide-details="auto"  outlined label="Consulta*"></v-textarea>
        </v-col>
      </v-row>
      <div style="color: red" class="ms-3">* Campo obligatorio</div>
    </v-form>
    <v-btn color="primary" @click="sendMail" class="py-3 mx-3"><v-spacer></v-spacer>{{ sending ? 'ENVIANDO' : 'ENVIAR'}}<v-spacer></v-spacer><v-icon v-if="!sending">mdi-send</v-icon><v-progress-circular v-else indeterminate ></v-progress-circular></v-btn>
    <ConfirmDialog ref="confirm"/>
  </v-container>
</template>

<script>
import { req } from "@/utils/validations.js";

export default {
  components: {
    ConfirmDialog: () => import("@/components/ConfirmDialog.vue"),
  },
  data() {
    return {
      sending: false,
      nombre: '',
      apellidos: '',
      email: '',
      message: '',
      rules: {
        req,
      },
    };
  },
  methods: {
    async sendMail(){
      this.sending = true
      try{
        if (!this.$refs.form.validate()) return
        const confirmation = (await this.$refs["confirm"].open(
          "¿Enviar consulta a RRHH?",
          "Se enviará un e-mail a rrhh@gmasfarre.com. ¿Continuar?"
        ));
        if (!confirmation) return
        await Promise.all([
          axios({
            method: "post",
            url: `/contacto_equipo`,
            data: {nombre: this.nombre, apellidos: this.apellidos, email: this.email, message: this.message},
          })
        ]);
        this.$root.$emit("snack", "Consulta enviada")
      }
      catch (e){
        console.log(e);
        this.$root.$emit("snack", "Error al enviar la consulta")
      }
      finally{
        this.sending = false
      }
    }
  },
};
</script>

<style>
.calDay{
  height: 40px !important;
  width: 40px !important;
  transition: background-color 0.1s ease;
}
.dark-badge .v-badge__badge .v-icon {
  color: black;
}
.light-badge .v-badge__badge .v-icon {
  color: white;
}
</style>